/* eslint-disable */
import React from "react";
import { Helmet } from "react-helmet";
import $ from 'jquery';
import menulogo from "../../images/menu-logo.svg"
import ReactDOM from "react-dom";
import Scrollspy from 'react-scrollspy'

import closemenu from "../../images/close-menu.svg"
import socialicon1 from "../../images/social-icon1.svg"
import socialicon2 from "../../images/social-icon2.svg"
import socialicon3 from "../../images/social-icon3.svg"
import socialicon4 from "../../images/social-icon4.svg"

const Home = (props) => {
  window.onload = function(){
    //hide the preloader
    document.querySelector(".preloader").style.display = "none";
}
  return (
      <>
    <Helmet>
      <title>Dreamland</title>
    </Helmet>
   
      <div> 
        <div className="preloader">
          <img src={menulogo} alt="menu-logo" /> 
          <div className="bar-sec">
          </div>
        </div>    
        <div className="menu-sec menu">
              <a href="#?" className="me-log">
                  <img src={menulogo} alt="menu-logo" />
              </a>
              <Scrollspy className="scrollspy" items={ ['home-sec','synopsis-sec', 'dreamers-sec','rarity-sec', 'irregularities-sec', 'roadmap-sec', 'vision-sec', 'team-sec', 'advocate-sec'] } currentClassName="current">
                {/* <ul> */}
                <li className="current"><a href="#home-sec">Home</a></li>
                <li><a href="#synopsis-sec">Synopsis</a></li>
                <li><a href="#dreamers-sec">dreamers</a></li>
                <li><a href="#rarity-sec">Rarity</a></li>
                <li><a href="#irregularities-sec">irregularities</a></li>
                <li><a href="#roadmap-sec">Roadmap</a></li>
                <li><a href="#vision-sec">Vision</a></li>
                <li><a href="#team-sec">Team</a></li>
                <li><a href="#advocate-sec">Dream advocate </a></li>
                {/* </ul> */}
              </Scrollspy>
              <a href="#synopsis-sec" className="scroll-down">scroll <div className="dots">
                <div className="dot dot-1"></div>
                  <div className="dot dot-2"></div>
                  <div className="dot dot-3"></div>
                  <div className="dot dot-4"></div>
                </div>
              </a>
        </div>
        <div className="mob-menu">
          <div className="mob-wrap">
          <a href="#?" className="close-menu">
               <img src={closemenu} alt="menu-logo" />
            </a>
            <Scrollspy className="scrollspy" items={ ['home-sec','synopsis-sec', 'dreamers-sec', 'rarity-sec', 'irregularities-sec', 'roadmap-sec', 'vision-sec', 'team-sec', 'advocate-sec'] } currentClassName="current">
                {/* <ul> */}
                <li className="current"><a href="#home-sec">Home</a></li>
                <li><a href="#synopsis-sec">Synopsis</a></li>
                <li><a href="#dreamers-sec">dreamers</a></li>
                <li><a href="#rarity-sec">Rarity</a></li>
                <li><a href="#irregularities-sec">irregularities</a></li>
                <li><a href="#roadmap-sec">Roadmap</a></li>
                <li><a href="#vision-sec">Vision</a></li>
                <li><a href="#team-sec">Team</a></li>
                <li><a href="#advocate-sec">Dream advocate </a></li>
                {/* </ul> */}
              </Scrollspy>
                <ul className="footer-social2">
                    <li><a href="https://twitter.com/DreamlandGenesi" target="_blank"> <img className="img1" src={socialicon1} alt="img"/>  </a></li>
                    <li><a href="https://discord.com/invite/dreamlandgenesis" target="_blank"> <img className="img2" src={socialicon2} alt="img"/></a></li>
                    <li><a href="https://medium.com/dreamland-genesis" target="_blank"> <img className="img3" src={socialicon3} alt="img"/></a></li>
                    <li><a href="#?" target="_blank"> <img className="img4" src={socialicon4} alt="img"/></a></li>
                </ul>
              <a href="/wallet" className="wallet-but2">Connect wallet</a>
          </div>
            
              <a href="#?" className="me-log">
                  <img src={menulogo} alt="menu-logo" />
              </a>
        </div>
      </div>
    </>
  );
};

export default Home;