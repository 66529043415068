/* eslint-disable */
import React from "react";
// import { Helmet } from "react-helmet";
import walletimg1 from "../../images/wallet-img.png"
import navback from "../../images/back-img.png"
import menulogo from "../../images/menu-logo.svg"
import walletimg2 from "../../images/wallet-icon.png"
import walletimg3 from "../../images/wallet-icon2.png"

import mintimg1 from "../../images/mint-img.png"
const Wallet = (props) => {
    window.onload = function(){
        //hide the preloader
        document.querySelector(".preloader").style.display = "none";
    }

    $(function() {

        (function quantityProducts() {
          var $quantityArrowMinus = $(".quantity-arrow-minus");
          var $quantityArrowPlus = $(".quantity-arrow-plus");
          var $quantityNum = $(".quantity-num");
      
          $quantityArrowMinus.click(quantityMinus);
          $quantityArrowPlus.click(quantityPlus);
      
          function quantityMinus() {
            if ($quantityNum.val() > 1) {
              $quantityNum.val(+$quantityNum.val() - 1);
            }
          }
      
          function quantityPlus() {
            $quantityNum.val(+$quantityNum.val() + 1);
          }
        })();
      
      });

    return (
<div>
<div className="preloader">
        <img src={menulogo} alt="menu-logo" /> 
        <div className="bar-sec"></div>
    </div>
    <div className="wallet-wrap">
    <a href="#" className="wallet-but">Connect wallet</a>
   
        <img className="main-img" src={walletimg1}/>
        <div className="sec1">
        <div className="alert alert-success">
  <strong>Success!</strong> Indicates a successful or positive action.
</div>
            <div className="sec3">
                <h4>phase:<span>Whitelist mint</span></h4>
            </div>
            <div className="sec2">
            <a href="/" className="nav-back-but">
            <img src={navback}/> Go back</a>
            <img src={mintimg1} alt="menu-logo" className="mint-img1" /> 
           
            <p>(10,000-10,000)</p>
            <h3>Amount to mint</h3>
            <div className="quantity-block">
  <button className="quantity-arrow-minus"> <i className="fas fa-chevron-left"></i> </button>
  <input className="quantity-num" type="number" value="1" />
  <button className="quantity-arrow-plus"> <i className="fas fa-chevron-right"></i> </button>
</div>
<ul>
<li><a href="#">  Mint </a></li>
</ul>
            </div>
        </div>
    </div>
</div>
    )
};

export default Wallet;
