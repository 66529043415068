/* eslint-disable */
import React from "react";
import socialicon1 from "../../images/social-icon1.svg"
import socialicon2 from "../../images/social-icon2.svg"
import socialicon3 from "../../images/social-icon3.svg"
import socialicon4 from "../../images/social-icon4.svg"
const Home = (props) => {
  return (
      <>

    <div> 
        <div className="footer-sec">
        @2021 dreamlandgenesis
        </div>
        <div className="social-wrap">
          <ul className="footer-social">
              <li><a href="https://twitter.com/DreamlandGenesi" target="_blank"> <img className="img1" src={socialicon1} alt="img"/>  </a></li>
              <li><a href="https://discord.com/invite/dreamlandgenesis" target="_blank"> <img className="img2" src={socialicon2} alt="img"/></a></li>
              <li><a href="https://medium.com/dreamland-genesis" target="_blank"> <img className="img3" src={socialicon3} alt="img"/></a></li>
              <li><a href="#?" target="_blank"> <img className="img4" src={socialicon4} alt="img"/></a></li>
          </ul>
        </div>
        
    </div>
    </>
  );
};

export default Home;