/* eslint-disable */
import React,{useEffect} from "react";
// import { Helmet } from "react-helmet";
import $ from 'jquery';

// eslint-disable-next-line
import Header from "../Inc/Header";
// eslint-disable-next-line
import Footer from "../Inc/Footer";

import faqimg1 from "../../images/faq-img1.png"
import navback from "../../images/back-img.png"
import menulogo from "../../images/menu-logo.svg"

import faqbgmob from "../../images/faq-bg-mob.png"

const Faq = (props) => {
useEffect(() => {
      window.onload = function(){
    //hide the preloader
    document.querySelector(".preloader").style.display = "none";
}
window.addEventListener("load", function(){
    // ....
    $(".faq_sec").addClass("active");
});
    // setTimeout(function() {
    //     $(".faq_sec").addClass("active");
    //   }, 5000);

    $('.accordion').find('.accordion-toggle').click(function() {
        $(this).next().slideToggle('600');
        $(".accordion-content").not($(this).next()).slideUp('600');
    });
    // $('.accordion-toggle').on('click', function() {
    //     $(this).toggleClass('active').siblings().removeClass('active');
    // });
    $('.accordion-wrap').on('click', function() {
        $('.accordion-toggle').removeClass('active');
        $(this).children().toggleClass('active');
    });

   

 }, []);
  return (
      <>
     
    <div> 
    <div className="preloader">
        <img src={menulogo} alt="menu-logo" /> 
        <div className="bar-sec"></div>
    </div>
    <div className="innerbanner">
    {/* <img src="images/innerban1.png" alt=""> */}
    {/* eslint-disable-next-line */}
    {/* <img src={faqimg1}/> */}
    <picture>
                <source srcSet={faqbgmob} media="(max-width: 767px)"/>
                <source srcSet={faqimg1}/>
                <img className="img1" src={faqimg1} alt="img" />  
            </picture> 
</div>
<div className="faq_sec">
<a href="/" className="nav-back-but">
{/* eslint-disable-next-line */}
<img src={navback}/> Go back</a>
<div className="title" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
      <div className="linesec">
                <span className="line1">
                        </span>
                        <span className="line2">
                        </span>                      
                </div>
              <div className="img-sec">
                <div className="zebra-wrap">
                    <div className="holder">
                        <span className="line1 pos-line1"></span>
                        <span className="line1 pos-line2"></span>
                        <span className="line1 pos-line3"></span>
                        <span className="line1 pos-line4"></span>
                        <span className="line1 pos-line5"></span>
                        <span className="line1 pos-line6"></span>
                        <span className="line1 pos-line7"></span>
                        <span className="line1 pos-line8"></span>
                        <span className="line1 pos-line9"></span>
                        <span className="line1 pos-line10"></span>
                        <span className="line1 pos-line11"></span>
                        <span className="line1 pos-line12"></span>
                        <span className="line1 pos-line13"></span>
                        <span className="line1 pos-line14"></span>
                        <span className="line1 pos-line15"></span>
                        <span className="line1 pos-line16"></span>
                        <span className="line1 pos-line17"></span>
                        <span className="line1 pos-line18"></span>

                        <span className="line1 pos-line19"></span>
                        <span className="line1 pos-line20"></span>
                        <span className="line1 pos-line21"></span>
                        <span className="line1 pos-line22"></span>
                        <span className="line1 pos-line23"></span>
                        <span className="line1 pos-line24"></span>
                        <span className="line1 pos-line25"></span>
                        <span className="line1 pos-line26"></span>
                        <span className="line1 pos-line27"></span>
                        <span className="line1 pos-line28"></span>
                        <span className="line1 pos-line29"></span>

                        <span className="line1 pos-line30"></span>
                        <span className="line1 pos-line31"></span>
                        <span className="line1 pos-line32"></span>
                        <span className="line1 pos-line33"></span>
                        <span className="line1 pos-line34"></span>
                        <span className="line1 pos-line35"></span>
                        <span className="line1 pos-line36"></span>
                        <span className="line1 pos-line37"></span>
                        <span className="line1 pos-line38"></span>
                        <span className="line1 pos-line39"></span>
                        <span className="line1 pos-line40"></span>

                        <span className="line1 pos-line41"></span>
                        <span className="line1 pos-line42"></span>
                        <span className="line1 pos-line43"></span>
                        <span className="line1 pos-line44"></span>
                    </div>             
                </div>                
              </div>
              <h2>
              faq
            </h2> 

            </div> 

        <div className="accordion">

        <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>1.</span>What makes Dreamland Genesis different? </h4>
                <div className="accordion-content">
                    <p>
                        More than you could possibly imagine. For starters, 
                        Dreamland Genesis is a long-term project that focuses on community and 
                        creativity above all else. We aim to empower our community through innovation, 
                        collaboration and by creating mutually beneficial opportunities together. 
                        We’re a team filled will doxed experts in their field, supported by influential and 
                        knowledgeable advisors that all want nothing more than long term utility and value for our community. 
                        We also aim to be one of the forefront leaders in Web 3.0, paving the way into the metaverse for NFT lovers, 
                        the masses joining the space and Dreamers alike.
                    </p>
                </div>
                </div>
        
        <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>2.</span>What is an NFT? </h4>
                <div className="accordion-content">
                    <p>
                    NFT stands for "Non-Fungible Token", It is a digital asset that 
                    translates to ownership of real-world objects which cannot be replicated. 
                    It's like a trading card that is completely 1/1, 
                    no others exactly the same in existence and even if you trade a card 
                    with another you'd end up with something completely unique.
                    </p>
                </div>
                </div>
                <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>3.</span>What can I do with my Dreamer? </h4>
                <div className="accordion-content">
                    <p>
                    Purchasing a Dreamer would give you full ownership and commercial rights over the NFT. 
                    Basically, you can do whatever you want with it! It also makes you a member of the Dreamland 
                    community, where there will be multiple benefits as we set off different milestones in the 
                    roadmap and begin rolling out our roadmap deliverables.
                    </p>
                </div>
                </div>
                <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>4.</span>Launch Details </h4>
                <div className="accordion-content">
                    <p>
                    Dreamland Genesis will kickstart the launch with an auction for the 1 of 1 Dreamers, 
                    followed by a staggered batched launch. 
                    The first batch will have a limited 1000 supply with Dream Access Presale followed by Public sales.
                    </p>
                </div>
                </div>
                <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>5.</span>How will the Auction work? </h4>
                <div className="accordion-content">
                    <p>
                    There will be a total of 10 1 of 1 Dreamers. 
                    A portion of the 1 of 1s will be raffled among our Dream Enthusiasts and sold at a 
                    flat rate to the winners of the raffle. 
                    The remaining 1 of 1 Dreamers will be  up for auction on Opensea. 
                    </p>
                </div>
                </div>
                <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>6.</span>Batched Launch</h4>
                <div className="accordion-content">
                    <p>
                    The Dreamland Genesis Project will be launching in batches, 
                    with the first batch being limited to 1000 supply. 
                    The first launch consists of a mixture of Dream Access sales and Public sales, 
                    with Dream Access sales available at a lower cost. </p>
                </div>
                </div>
                <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>7.</span>How do I get Dream Access?</h4>
                <div className="accordion-content">
                    <p>
                    Dream Access slots are given out in the community through discord, 
                    twitter and to event winners or participants!</p>
                    <p>There's no fixed rule! Just by having an amazing time and by making another 
                        Dreamers day you can win a Dream Access slot! </p>
                </div>
                </div>

                <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>8.</span>How do I use Metamask? </h4>
                <div className="accordion-content">
                    <p>
                        It’s simple really go to 
                        {/* eslint-disable-next-line */}
                        <a href="https://metamask.io/" target="_blank"><b> Metamask</b></a> or follow this <a href="https://www.youtube.com/watch?v=Af_lQ1zUnoM." target="_blank"><b>Video</b></a>.
                         </p>

                    <p>You will need some ETH in order to purchase a Dreamland Genesis NFT. You can buy it on exchanges like Coinbase, Binance or even directly from the Metamask Wallet.
                    </p>
                </div>
                </div>
                <div className="accordion-wrap">
                <h4 className="accordion-toggle"><span>9.</span>Key Information </h4>
                <div className="accordion-content">
                    <p>
                    There will only ever be 10,000 Dreamland Genesis Tokens in existance. 
                    </p>
                    <ul>
                        <li>Auction Date — Before Christmas </li>
                        <li>1st Batch Launch of 1,000 — End December</li>
                        <li>Price per token (Dream Access Presale) — 0.050 </li>
                        <li>Price per token (Public Sale) — 0.055 </li>
                        <li>Token type — ERC-721 </li>
                        <li>Blockchain — Ethereum</li>
                    </ul>
                </div>
                </div>
        </div>

</div>
    </div>
    
    </>
  );
};

export default Faq;
