import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home'
import Faq from './components/Faq/Faq'
import Mint from './components/Mint/Mint'
import AOS from "aos";
import "aos/dist/aos.css";


 AOS.init();


function App() {
  return (
    <Routes>
			<Route path="/" exact element={<Home/>} /> 
      <Route path="/faq" exact element={<Faq/>} />     
      <Route path="/mint" exact element={<Mint/>} />     
    </Routes>
  );
}

export default App;
 
